import './App.css';
import React, { useRef, useEffect } from 'react';

function App() {

  const containerRef = useRef(null);
  const [width, setWidth] = React.useState(500);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.window.innerWidth/3);
      if(window.window.innerWidth<720){
        setWidth(window.window.innerWidth/2);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='text-center'>
      <div className='my-5'>
        <p className='text-xl'>notpink</p>
        <p className='text-xs font-serif font-light'>an art studio from amsterdam, netherlands</p>
        <div className='flex gap-3 justify-center'>
          <a className='text-xs font-serif font-light underline' href="https://x.com/notpinkart">x.com</a>
          <a className='text-xs font-serif font-light underline' href="https://www.instagram.com/notpink.art">instagram</a>
        </div>
      </div>
      <div className='flex flex-wrap justfiy-center'>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104346.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104348.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104351.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/GMLtHULXkAIkUE- (1).jpeg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104354.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104357.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104402.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104408.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104411.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104416.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104418.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104439.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104506.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/20240619_104509.jpg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/GIob6LVXcAEGfFx.jpeg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/GIob6OEWkAA5giu.jpeg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/GJlipvwXMAACnBM.jpeg"></img>
        <img className={`h-[${Math.floor(width*4/3)}px] w-[${Math.floor(width)}px]`} src="photos/GLs_H2CXYAAdxat.jpeg"></img>
      </div>
      <div className='my-5'>
        <p className='text-xs font-serif font-light'>an art studio from amsterdam, netherlands</p>
        <div className='flex gap-3 justify-center'>
          <a className='text-xs font-serif font-light underline' href="https://x.com/notpinkart">x.com</a>
          <a className='text-xs font-serif font-light underline' href="https://www.instagram.com/notpink.art">instagram</a>
        </div>
      </div>
    </div>
  );
}

export default App;
